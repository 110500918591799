// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as Link from "../../../../../../styleguide/components/Link/Link.res.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Routes_Location from "../../../../../../routes/common/Routes_Location.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as Routes_LocationBrochure from "../../../../../../routes/common/Routes_LocationBrochure.res.js";
import * as LocationsDashboardNavbarScss from "./LocationsDashboardNavbar.scss";

var css = LocationsDashboardNavbarScss;

function LocationsDashboardNavbar(props) {
  var slug = props.slug;
  var id = props.id;
  var selectedCategory = props.selectedCategory;
  var id$1 = id !== undefined ? Caml_option.valFromOption(id) : 0;
  var slug$1 = slug !== undefined ? slug : "";
  var linkCss = function (category) {
    if (category === selectedCategory) {
      return Cx.cx([
                  css.linkContainer,
                  css.underlinedLink
                ]);
    } else {
      return css.linkContainer;
    }
  };
  return JsxRuntime.jsx("div", {
              children: JsxRuntime.jsxs("div", {
                    children: [
                      JsxRuntime.jsx("div", {
                            children: JsxRuntime.jsx(Link.make, {
                                  href: Routes_Location.Dashboard.index,
                                  className: css.linkItem,
                                  children: "Locations"
                                }),
                            className: linkCss("Locations")
                          }),
                      JsxRuntime.jsx("div", {
                            children: JsxRuntime.jsx(Link.make, {
                                  href: Routes_LocationBrochure.Dashboard.index,
                                  className: css.linkItem,
                                  children: "Brochures"
                                }),
                            className: linkCss("Brochures")
                          }),
                      selectedCategory === "EditBrochure" ? JsxRuntime.jsxs(JsxRuntime.Fragment, {
                              children: [
                                JsxRuntime.jsx("div", {
                                      children: JsxRuntime.jsx(Link.make, {
                                            href: Routes_LocationBrochure.Dashboard.edit(id$1),
                                            className: css.linkItem,
                                            children: "Edit Brochure"
                                          }),
                                      className: Cx.cx([
                                            css.linkContainer,
                                            css.underlinedLink
                                          ])
                                    }),
                                JsxRuntime.jsx("div", {
                                      children: JsxRuntime.jsx(Link.make, {
                                            href: Routes_LocationBrochure.show(slug$1),
                                            className: css.linkItem,
                                            children: "View"
                                          }),
                                      className: css.linkContainer
                                    })
                              ]
                            }) : (
                          selectedCategory === "NewBrochure" ? JsxRuntime.jsx("div", {
                                  children: JsxRuntime.jsx(Link.make, {
                                        href: Routes_LocationBrochure.Dashboard.$$new,
                                        className: css.linkItem,
                                        children: "New Brochure"
                                      }),
                                  className: Cx.cx([
                                        css.linkContainer,
                                        css.underlinedLink
                                      ])
                                }) : null
                        )
                    ],
                    className: css.barContainer
                  }),
              className: css.barWrapper
            });
}

var make = LocationsDashboardNavbar;

export {
  css ,
  make ,
}
/* css Not a pure module */
